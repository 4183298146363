import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CSectTitle,
  CTab,
  CTabItem,
  CBtnList,
  CKv,
  CNewsList,
  CPanelCard,
  Search,
  CAccessMedia,
  CBgMedia,
  CSeparateImgMedia,
  LBgSect,
  LPickup,
  CHeroMedia02,
  CScrollcard,
  CPopupBanner,
  CYoutube,
} from '../components/_index';
import infoChoice from '../utils/info-choice';
import infoGet from '../utils/info-get';
import releaseChoice from '../utils/release-choice';
import releaseGet from '../utils/release-get';
import { setLang2 } from '../utils/set-lang2';

// markup
const IndexPage = () => {

  const lang = setLang2();

  return (
    <Layout isTop={true}>
      <SEO ogtype={'website'} />
      <CKv
        logo={{
          src: '/assets/images/common/logo_white.svg',
          alt: 'SENDAI ROYAL PARK HOTEL',
        }}
        title={
          <>
            気軽に、ちょっと贅沢に
            <br />
            「仙台リゾートステイ」
          </>
        }
        data={[
          {
            img: {
              src: '/assets/images/home/kv.png',
            },
            imgSp: {
              src: '/assets/images/home/kv__sp.png',
            },
          },
          {
            img: {
              src: '/assets/images/home/kv02.png',
            },
            imgSp: {
              src: '/assets/images/home/kv02__sp.png',
            },
          },
          {
            img: {
              src: '/assets/images/home/kv03.png',
            },
            imgSp: {
              src: '/assets/images/home/kv03__sp.png',
            },
          },
          {
            img: {
              src: '/assets/images/home/kv04.png',
            },
            imgSp: {
              src: '/assets/images/home/kv04__sp.png',
            },
          },
        ]}
      />

      <Search formId="toppage" />

      {lang != 'ja' && lang != 'en' ? (
        <section className="u_bgGrad l_sect u_pb0">
          <h2 className="c_headingLv2">
            飯店週邊資訊／推薦景點（秋冬）
          </h2>
          <CYoutube
            video={{
              src: "/assets/movie/inbound-AW-FHD_ol.mp4",
            }}
          />
        </section>
      ) : null} 

      <LPickup />
      <section className="u_bgGrad l_sect u_pb0 u_mbExLarge">
        <LWrap>
          <h2 className="c_headingLv2">
            気軽に、ちょっと贅沢に
            <br />
            「仙台リゾートステイ」
          </h2>
          <p className="c_sectLead">
            杜の都のリゾートの開放感と心が躍るおもてなしに日々の疲れが癒される。
            <br />
            また訪れたくなる、他では味わえない、
            <br />
            ここだけの特別な時間を思うがままにおたのしみください。
          </p>
          <CHeroMedia02
            data={[
              {
                title: (
                  <>
                    すぐそこにあるリゾートで
                    <br />
                    非日常のOMOTENASHIを
                    <span className="">「心ゆくまで、たのしむ」</span>
                  </>
                ),
                img: {
                  src: '/assets/images/stay/img_hero.png',
                  alt: '',
                },
                text: (
                  <>
                    仙台駅から車で約30分とは思えない心が落ち着く自然溢れる美しい景色とヨーロッパの趣を感じる体がほぐれる優雅で快適な空間。
                    <br />
                    杜の都を訪れる要人を迎えてきたOMOTENASHIを思う存分おたのしみください。
                  </>
                ),
                btn: {
                  label: 'ホテルの魅力',
                  link: {
                    href: '/about/',
                  },
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <LBgSect
        img={{
          src: '/assets/images/home/img_guestroom.png',
        }}
        imgSp={{
          src: '/assets/images/home/img_guestroom__sp.png',
        }}
      >
        <CSectTitle
          title={{ ja: 'ゲストルーム', en: 'guest room' }}
          color="white"
          exClass={'en2'}
        />
        <p className="c_sectLead u_colorWhite u_mbMedium">
          古き良きヨーロッパの荘園領主の館のような気品溢れる多彩なゲストルーム。
          <br />
          仙台のリゾートステイを優雅に快適におたのしみいただけます。
        </p>
        <CBtnList
          data={[
            {
              label: '宿泊',
              link: {
                href: '/stay/',
              },
              color: 'borderWhite',
            },
          ]}
        />
      </LBgSect>
      <section className="l_sect">
        <LWrap>
          <CSectTitle
            title={{ ja: 'レストラン', en: 'restaurant' }}
            exClass={'en2'}
          />
          <CSeparateImgMedia
            data={[
              {
                imgList: [
                  {
                    img: {
                      src: '/assets/images/home/img_restaurant.png',
                      alt: '',
                    },
                    imgSp: {
                      src: '/assets/images/home/img_restaurant__sp.png',
                      alt: '',
                    },
                  },
                  {
                    img: {
                      src: '/assets/images/home/img_restaurant02.png',
                      alt: '',
                    },
                    imgSp: {
                      src: '/assets/images/home/img_restaurant02__sp.png',
                      alt: '',
                    },
                  },
                ],
                title: (
                  <>
                    想い出になる
                    <br />
                    仙台リゾートの味と時間
                  </>
                ),
                text: (
                  <>
                    気軽にシェフの珠玉の料理を味わえるリゾートレストラン。
                    <br />
                    四季折々の美しい景色の中で旬の味覚を贅沢に存分におたのしみいただけます。
                  </>
                ),
                btn: {
                  label: 'レストラン',
                  link: {
                    href: '/restaurants/',
                  },
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbExLarge">
        <LWrap>
          <CSectTitle title={{ ja: '過ごす', en: 'stay' }} exClass={'en2'} />
          <CBgMedia
            data={[
              {
                img: {
                  src: '/assets/images/home/img_stay.png',
                },
                imgSp: {
                  src: '/assets/images/home/img_stay__sp.png',
                },
                text: (
                  <>
                    街には、その地で暮らし人たちの輝き、
                    <br />
                    そこにしかない歴史と文化があります。
                    <br />
                    私たちは、その街の息吹を感じ、
                    <br className="u_pc" />
                    人に触れ、創意工夫を重ねることで、
                    <br className="u_pc" />
                    その街ならではのおもてなしを
                    <br className="u_pc" />
                    お客様にご提供します。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="">
        <CPanelCard
          data={[
            {
              img: {
                src: '/assets/images/home/img_panel05.png',
              },
              imgSp: {
                src: '/assets/images/home/img_panel05__sp.png',
              },
              title: <>ウエディング</>,
              subTitle: (
                <>
                  これからの時代に、
                  <br className="u_pc" />
                  結婚式を。
                </>
              ),
              text: (
                <>
                  誓いと感謝、結婚式の原点。
                  <br className="u_sp" />
                  ふたりで人生を歩むことを誓う、
                  <br className="u_sp" />
                  みんなに溢れる感謝を伝える。
                  <br />
                  大切な人たちとの想い出をつくり、
                  <br className="u_sp" />
                  かけがえのない絆を結ぶ。
                  <br />
                  たくさんの喜びと元気をくれる、
                  <br className="u_sp" />
                  結婚式のチカラ。
                </>
              ),
              btn: {
                label: <>詳しくはこちら</>,
                link: {
                  href: '/wedding/',
                },
              },
            },
          ]}
        />
        <CPanelCard
          data={[
            {
              img: {
                src: '/assets/images/home/img_panel.png',
              },
              imgSp: {
                src: '/assets/images/home/img_panel__sp.png',
              },
              title: <>イベント&アクティビティ</>,
              subTitle: (
                <>
                  心身を整える
                  <br />
                  杜の都の寛ぎと癒やし
                </>
              ),
              text: (
                <>
                  日々の喧騒を忘れる杜の都のリゾートアクティビティ。
                  <br />
                  自然に囲まれた環境ならではの
                  <br className="u_pc" />
                  開放感溢れる体験を自由におたのしみいただけます。
                </>
              ),
              btn: {
                label: <>詳しくはこちら</>,
                link: {
                  href: '/experience/activity/',
                },
              },
            },
            {
              img: {
                src: '/assets/images/home/img_panel02.png',
              },
              imgSp: {
                src: '/assets/images/home/img_panel02__sp.png',
              },
              title: <>エステ</>,
              subTitle: (
                <>
                  癒やしの先の健康美
                  <br />
                  リゾートで満喫する
                  <br className="u_sp" />
                  ちょっと贅沢なエステ
                </>
              ),
              text: (
                <>
                  アーユソーマでは、スイーツセット付きプランや記念日に最適なプラン、
                  <br className="u_pc" />
                  ブライダルエステプランなどもご用意しております。
                  <br className="u_pc" />
                  お客様のシーンにあわせてご利用ください。
                </>
              ),
              btn: {
                label: <>詳しくはこちら</>,
                link: {
                  href: '/aesthetic/',
                },
              },
            },
            {
              img: {
                src: '/assets/images/home/img_panel03.png',
              },
              imgSp: {
                src: '/assets/images/home/img_panel03__sp.png',
              },
              title: <>お祝い</>,
              subTitle: (
                <>
                  リゾートホテルならでは
                  <br />
                  ちょっと贅沢なお祝い
                </>
              ),
              text: (
                <>
                  開放感溢れる環境と在仙デザイナーが手掛けた
                  <br className="u_pc" />
                  印象的な空間は大切な人との大切な記念日に最適です。
                  <br />
                  非日常で叶える、ここにしかないお祝いを。
                </>
              ),
              btn: {
                label: <>詳しくはこちら</>,
                link: {
                  href: '/oiwai/',
                },
              },
            },
            {
              img: {
                src: '/assets/images/home/img_panel04.png',
              },
              imgSp: {
                src: '/assets/images/home/img_panel04__sp.png',
              },
              title: <>宴会・会議</>,
              subTitle: (
                <>
                  有意義な時間を創る
                  <br />
                  開放的な環境と選べる会場
                </>
              ),
              text: (
                <>
                  杜の都に相応しい自然溢れるリゾート環境と
                  <br className="u_pc" />
                  幅広いご要望に対応できる大小さまざまな会場が
                  <br />
                  親睦を深める宴会や生産性の高い会議の後押しをします。
                </>
              ),
              btn: {
                label: <>詳しくはこちら</>,
                link: {
                  href: '/banquet/',
                },
              },
            },
          ]}
        />
      </section>
      <section className="u_bgGray l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'お知らせ', en: 'INFORMATION' }} />
          <CTab defaultKey={0}>
            <CTabItem title="最新情報" tabKey={0}>
              <CNewsList data={infoChoice(infoGet(), '', 5)} />
              <CBtnList
                data={[
                  {
                    label: 'お知らせ一覧',
                    link: {
                      href: '/information/',
                    },
                    color: 'borderTheme',
                  },
                ]}
              />
            </CTabItem>
            <CTabItem title="ニュースリリース" tabKey={1}>
              <CNewsList data={releaseChoice(releaseGet(), 5)} />
              <CBtnList
                data={[
                  {
                    label: 'ニュースリリース一覧',
                    link: {
                      href: '/release/',
                    },
                    color: 'borderTheme',
                  },
                ]}
              />
            </CTabItem>
          </CTab>
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'アクセス', en: 'ACCESS' }} />
          <CAccessMedia
            data={[
              {
                text: (
                  <>
                    <p>
                      〒981-3204
                      <br />
                      仙台市泉区寺岡6-2-1
                    </p>
                    <p>
                      <a href="TEL:0223771111">TEL:022-377-1111（代表）</a>
                    </p>
                  </>
                ),
                btnList: [
                  {
                    label: 'アクセス',
                    link: {
                      href: '/access/',
                      blank: true,
                    },
                    icon: 'arrow',
                    color: 'borderTheme',
                  },
                  {
                    label: 'Google MAP',
                    link: {
                      href: 'https://www.google.com/maps?ll=38.342863,140.837966&z=16&t=m&hl=ja&gl=JP&mapclient=embed&cid=12978498071859459586',
                      blank: true,
                    },
                    icon: 'arrow',
                    color: 'borderTheme',
                  },
                ],
              },
            ]}
          />
        </LWrap>
      </section>

      {lang == 'ja'? (
      <CPopupBanner
        data={[
          {
            img: {
              src: '/assets/images/home/img_popup.png',
              alt: '',
            },
            link: {
              href: 'https://www.srph.co.jp/wedding/sp/30th/',
              blank: false,
            },
            size: {
              width: '280px',
              height: '280px',
            },
          },
        ]}
      />
    ) : null} 

    </Layout>
  );
};

export default IndexPage;
